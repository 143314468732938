import { useKeenSlider } from 'keen-slider/react'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

export function useKeenSliderRefs({
  interactionTitle,
  slidesNum,
  slidesPerView,
  spacing,
  initialSlide,
  origin,
  align = true,
  loop = false,
  sliderDisabled = false,
  onDetailsChanged = undefined,
  ...additionalOptions
}) {
  const [isMounted, setIsMounted] = React.useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    disabled: Boolean(sliderDisabled),
    initial: initialSlide,
    loop,
    range: {
      align,
    },
    mode: 'free-snap',
    slides: {
      origin,
      number: slidesNum,
      spacing,
      perView: slidesPerView
    },
    detailsChanged: (s) => {
      if (onDetailsChanged) onDetailsChanged(s.track.details)
    },
    dragChecked(s) {
      trackInteraction({
        title: interactionTitle,
        action: 'dragged',
        type: 'slider',
        index: s.track.details.rel
      })
    },
    created: () => setIsMounted(true),
    ...additionalOptions
  })

  React.useEffect(
    () => {
      instanceRef.current.update()
    },
    [instanceRef]
  )

  return { sliderRef, instanceRef, isMounted }
}
