import { reportClientError } from '/machinery/reportClientError'
import { mapJob } from '/machinery/tracking/metadata'

export function pushToDataLayer(data) {
  window.dataLayer = window.dataLayer || [];
  [].concat(data).forEach(x => {
    try {
      if (!x) throw new Error(`No data given to pushToDataLayer function`)
      window.dataLayer.push(removeUndefinedValues(x))
    } catch (e) {
      reportClientError(e)
    }
  })
}

export function trackInteraction({ title, action, type, index = undefined, extraInteractionData = {}, extraMetaData = {} } ) {
  pushToDataLayer({
    event: 'interaction',
    metadata: {
      interaction: {
        title,
        action,
        type,
        index,
        ...extraInteractionData
      },
      ...extraMetaData,
    }
  })
}

/* ref || documentType || 'job' */
export function internalLinkDataX(documentTypeOrRef) {
  const isDocumentType = typeof documentTypeOrRef === 'string'

  return isDocumentType
    ? `link-to-${documentTypeOrRef}`
    : refToDataXLink(documentTypeOrRef)
}

function refToDataXLink(ref) {
  const { _type } = ref ?? {}
  if (!_type) console.error(`_type is missing in ref for data-x link-to`)
  return _type ? `link-to-${_type}` : ''
}

function removeUndefinedValues(data) {
  return JSON.parse(JSON.stringify(data))
}

export function trackEcommerceVacancyCheckoutEvent(data) {
  pushToDataLayer({
    event: 'begin_checkout',
    metadata: {
      job: mapJob(data)
    }
  })
}

export function trackApplicationFinished(job) {
  pushToDataLayer({
    event: 'purchase',
    metadata: {
      job: {
        ...mapJob(job),
        applicationtype: 'application-form'
      }
    }
  })
}

export function trackApplicationStarted(job) {
  pushToDataLayer({
    event: 'purchase',
    metadata: {
      job: {
        ...mapJob(job),
        applicationtype: 'whatsapp'
      }
    }
  })
}
