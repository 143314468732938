import { Icon } from '/features/buildingBlocks/Icon'

import styles from './SliderButtons.css'

import arrowIcon from '/images/icons/arrow-right.raw.svg'
import { useTranslate } from '/machinery/I18n'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

export function SliderButtons({
  interactionTitle,
  handleClick,
  currentSlide,
  reachedEnd = false,
  loop = false
}) {
  const { __ } = useTranslate()

  return (
    <div className={styles.component}>
      <SliderButton
        onClick={handlePrevious}
        disabled={!loop && currentSlide === 0}
        ariaLabel={__`previous-slide-aria-label`}
        {...{ currentSlide }}
      />

      <SliderButton
        onClick={handleNext}
        disabled={!loop && reachedEnd}
        ariaLabel={__`next-slide-aria-label`}
        {...{ currentSlide }}
      />
    </div>
  )

  function handlePrevious() {
    const index = currentSlide - 1

    handleClick(index)
    trackInteraction({
      title: interactionTitle,
      action: 'clicked',
      type: 'slider',
      index
    })
  }

  function handleNext() {
    const index = currentSlide + 1

    handleClick(index)
    trackInteraction({
      title: interactionTitle,
      action: 'clicked',
      type: 'slider',
      index
    })
  }
}

function SliderButton({ onClick, disabled, ariaLabel }) {
  return (
    <button
      type='button'
      data-x='click-slider-button'
      className={styles.componentSliderButton}
      aria-label={ariaLabel}
      {...{ onClick, disabled }}
    >
      <span className={styles.iconContainer}>
        <Icon icon={arrowIcon} layoutClassName={styles.iconLayout} />
      </span>
    </button>
  )
}
